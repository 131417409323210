<template>
  <section class="all-templates-view">
    <el-row type="flex" justify="center" align="center">
      <el-col :span="22">
        <el-row style="margin-top: -10px; margin-bottom: 5px;">
                      <router-link to="/apps">
                              <el-button size="mini"> <i class="el-icon-back"></i> back</el-button>
                      </router-link>
        </el-row>
        <el-row style="margin-bottom: 20px">
          <el-col :lg="6" :md="6" :sm="24" :xs="24">
            
            <h3
              class="title fw-normal text-dark mb-0 ls-05"
            >{{ 'Installed Apps' }}</h3>
          </el-col>
          <el-col
            :lg="18"
            :md="18"
            :sm="24"
            :xs="24"
            style="text-align: right"
            class="top-buttons-wrapper"
          >
          <!-- <el-select v-model="selectedStatus" placeholder="Select" style="margin-right: 15px;" clearable>
                          <el-option v-for="item in publishStatus" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                      
          </el-select> -->
            <el-input
              class="search"
              placeholder="Search"
              v-model="search_string"
              clearable
              @input="loadData"
              :style="getIsMobile ? 'width:130px' : 'width:170px;'"
            ></el-input>
            <!-- <el-button
              v-if="checkPermissionByPermissionName('addApp')"
              plain
              :style="getIsMobile ? 'width:130px' : 'width:170px;'"
              class="create-btn px-4 ml-2"
              type="primary"
              @click="newSolution"
            >Build New Solution</el-button> -->
          </el-col>
        </el-row>
        <div
          v-loading="configureLoading"
          element-loading-text="Loading..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          class="vue-data-table-default"
        >
          <data-tables-server
            :fullscreen="true"
            :total="total"
            :data="allApplications"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :pagination-props="paginationProps"
            style="width: 100%"
            v-if="!getIsMobile"
            @query-change="loadData"
            @sort-change="sortChange"
          >
            <el-table-column
              label="Name"
              id="name"
              prop="title"
              width="300"
              fixed="left"
              sortable="custom"
            >
              <template slot-scope="scope">
                <a class="doc-title">
                  {{ scope.row.app_name | truncate(25, "...") }}
                  <!-- <i
                    class="el-icon-star-on"
                    v-if="scope.row.entity_type === 'BUSINESS'"
                  ></i>
                  <i class="el-icon-user" v-if="scope.row.entity_type === 'INDIVIDUAL'"></i>

                  <i class="el-icon-magnet" v-if="scope.row.entity_type === 'GENERAL'"></i> -->
                </a>
              </template>
            </el-table-column>
            <el-table-column label="Actions" prop="action" class="action" width="150">
              <template slot-scope="scope">
                <!-- slot-scope="scope" -->
                <ul class="action-buttons hover-buttons">
                  <li>
                    <el-dropdown>
                      <el-button size="mini" class="el-dropdown-link px-2">
                        Actions
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                         <!-- <a @click="onView(scope.row)" v-if="checkPermissionByPermissionName('viewApp')" style="border: none">
                          <el-dropdown-item icon="el-icon-view" command="view">View</el-dropdown-item>
                        </a> -->
                        <a v-if="checkPermissionByPermissionName('deleteApp')" @click="onUninstall(scope.row)" style="border: none">
                          <el-dropdown-item icon="el-icon-delete" command="delete">Uninstall</el-dropdown-item>
                        </a>
                        <!-- <a v-if="scope.row.publish == 'ACTIVE'" @click="onInactive(scope.row)" style="border: none">
                          <el-dropdown-item icon="el-icon-edit" command="delete">Make InActive</el-dropdown-item>
                        </a>
                        <a v-if="scope.row.publish == 'ACTIVE'" @click="onActive(scope.row)" style="border: none">
                          <el-dropdown-item icon="el-icon-edit" command="delete">Make Active</el-dropdown-item>
                        </a> -->
                      </el-dropdown-menu>
                    </el-dropdown>
                  </li>
                </ul>
              </template>
            </el-table-column>
            <!-- <el-table-column label="Status" id="status" prop="publish" width="200" sortable="custom">
              <template slot-scope="scope">
                <div class="d-flex activestatus">
                  <div :class="scope.row.publish.toLowerCase() + ' circle'"></div>  
                  <div>
                    <p v-if="scope.row.publish == 'ACTIVE'">Active</p>
                    <p v-else-if="scope.row.publish == 'INACTIVE'">Inactive</p>
                    <p v-else-if="scope.row.publish == 'HOLD'">Hold</p>
                    <p v-else>Declined</p>
                  </div>
                </div>
              </template>
            </el-table-column> -->
            <el-table-column
              label="Installation Type"
              prop="install_as_workspace"
              sortable="custom"
              min-width="200"
            >
              <template slot-scope="scope">
                <!-- {{
                scope.row.install_as_workspace
                }} -->
              <span v-if="scope.row && scope.row.install_as_workspace">New Workspace</span>
              <span v-else>same company</span>

              </template>
            </el-table-column>
            <el-table-column
              label="Last Modified"
              prop="last_modified"
              sortable="custom"
              min-width="200"
            >
            <template slot-scope="scope">
                {{
                scope.row.updated_at | globalDateTimeFormat
                }}
              </template>
            </el-table-column>
            <el-table-column
              sortable="custom"
              min-width="100"
            >
            <template slot-scope="scope">
              <el-tooltip
              class="item" effect="dark" content="Go to workspace" placement="right-start">
                  <i v-if="scope.row.install_as_workspace" class="el-icon-top-right" @click="changeWorkspace(scope.row)" style="cursor:pointer; color:#409EFF;"></i>
                  <i v-else class="el-icon-minus"></i>
              </el-tooltip>
              </template>
             
            </el-table-column>
          </data-tables-server>
        </div>
        <data-tables-server
          :fullscreen="true"
          :total="total"
          :data="allApplications"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationProps"
          style="width: 100%"
          v-if="getIsMobile"
          @query-change="loadData"
          @sort-change="sortChange"
          class="entities-list-mobile"
        >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <div class="expand-column-item">
                <div class="expand-column-item-label">Status:</div>
                <div class="expand-column-item-content">
                  <div class="d-flex activestatus">
                    <!-- <div :class="scope.row.status.toLowerCase() + ' circle'"></div> -->
                    <div>
                      <p v-if="scope.row.status == 'ACTIVE'">Active</p>
                      <p v-else-if="scope.row.status == 'INACTIVE'">Inactive</p>
                      <p v-else>Deleted</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="expand-column-item">
                <div class="expand-column-item-label">Last Modified:</div>
                <div class="expand-column-item-content">
                  <template v-if="scope.row.updated_at">
                    <span
                      class="fs-8 d-inline-block pr-2 text-muted"
                    >{{ scope.row.updated_at | globalDateTimeFormat }}</span>
                  </template>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Name" id="name" min-width="120" fixed>
            <template slot-scope="scope">
              <a class="doc-title">
                {{ scope.row.name | truncate(15, "...") }}
              </a>
            </template>
          </el-table-column>

          <el-table-column prop="action" min-width="100" fixed>
            <template slot-scope="scope">
              <ul class="action-buttons hover-buttons">
                <li>
                  <el-dropdown>
                    <el-button size="mini" class="el-dropdown-link px-2">
                      Actions
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <!-- <a @click="onView(scope.row)" style="border: none">
                        <el-dropdown-item icon="el-icon-view" command="view">View</el-dropdown-item>
                      </a> -->
                      <a @click="onUninstall(scope.row)" style="border: none">
                        <el-dropdown-item icon="el-icon-delete" command="delete">Uninstall</el-dropdown-item>
                      </a>
                    </el-dropdown-menu>
                  </el-dropdown>
                </li>
              </ul>
            </template>
          </el-table-column>
        </data-tables-server>
      </el-col>
      <!-- <el-dialog
        :title=SelectedAppData.name
        :visible.sync="viewAppDialog"
        class="view-application"
        v-loading="appLoading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :close-on-click-modal="false"
        :close-on-press-escape="false">
        <el-card class="box-card mb-1 card" shadow="hover">
              <div class="card-content">
                <div class="left-content">
                  <img v-if="SelectedAppData.icon" :src="require('@/assets/img/icons/menu/' + SelectedAppData.icon)" class="imgSize" />
                  <img v-else src="require('@/assets/img/icons/menu/company-document-2.svg')" alt="menu"
                    class="imgSize" />
                  <div class="text-wrapper">
                    <span>
                      <h5 style="margin-bottom: -1px;">{{ SelectedAppData.name }}</h5>
                    </span>
                  </div>
                </div>
              </div>
              <div class="text text-justify text-ellipsis mt-3" style="margin-bottom: 10px;">
                <span v-if="SelectedAppData.description">{{ SelectedAppData.description }}</span>
                <span v-else>
                  Employee management involves the activities and performance of employees within an organization.
                </span>
              </div>
              <div>
                <h6 v-if="SelectedAppData.version && SelectedAppData.version[0].entities"> Entities : {{ SelectedAppData.version[0].entities.length }}</h6>
                <h6 v-if="SelectedAppData.version && SelectedAppData.version[0].menuManagementWithRoles"> Roles : {{ SelectedAppData.version[0].menuManagementWithRoles.length }}</h6>
                <h6 v-if="SelectedAppData.version && SelectedAppData.version[0]"> Version : {{ SelectedAppData.version[0].version }}</h6>
                <h6 v-if="SelectedAppData"> Status : {{ SelectedAppData.publish }}</h6>
                
              </div>
            </el-card>
      </el-dialog> -->
    </el-row>

  </section>
</template>
<script>
import { mapGetters } from "vuex";
import PermissionsHelper from "@/mixins/permissionsHelper";
import NavigationHelper from "@/mixins/navigationHelper";

export default {
  name: "Installed-AppList",
  data() {
    return {
      search_string: "",
      configureLoading: false,
      total: 0,
      activeStep: 0,
      currentPage: 1,
      pageSize: 0,
      page: 1,
      newEntityModal: false,
      entityName: "",
      description: "",
      isAccount: false,
      entity_type: "GENERAL",
      modalLoading: false,
      allApplications: [],
      showAddGroupDialog: false,
      groupName: "",
      loadingSaveGroup: false,
      entityGroupsData: [],
      allTemplates:[],
      allRoles:[],
      newAppText:'Loading....',
      publishApp:{},
      viewAppDialog : false,
      appLoading : false,
      SelectedAppData : {},
      publishStatus: [{
              value: 'HOLD',
              label: 'Hold'
          }, {
              value: 'DECLINED',
              label: 'Declined'
          }, {
              value: 'ACTIVE',
              label: 'Active'
          }, {
              value: 'INACTIVE',
              label: 'Inactive'
          },
      ],
      selectedStatus: ''
    };
  },
  mixins: [PermissionsHelper, NavigationHelper],
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
      "getWorkspaceList"
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile"
    ]),
    ...mapGetters("company", [
    "getCompanyDetails",
    "getUpdateCompany",
    "getCompanyLogo",
    "getSingleCompanyLogo",
  ]),
  ...mapGetters("apps", ["getAllApps", "getAppErrors","getAppDeletionStatus","getAppDeletionErrors","getInstalledApplications","getAppUninstallStatus"]),

    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50]
      };
    }
  },
  async mounted() {
    await this.getCompanyInfo();
    if(this.getCompanyDetails && (this.getCompanyDetails.is_admin_clone || this.getCompanyDetails.is_cloned)){
    this.$notify.error({
                      title: "Error",
                      message: "You can't Access Published apps in an application"
                      })
    this.$router.push({ path: "/dashboard" });
    }
    else{
        await this.fetchApplications();
    }
  },
  methods: {
    beforeDestroy() {
      this.$store.commit(
        "navigationOpen/setCurrentMenu",
        {},
        {
          root: true
        }
      );
    },
    /*  ***Fetch Groups  end  **** */
    async sortChange(a) {
      console.log(a)
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.fetchApplications();
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.fetchApplications();
      }
    },
    async loadData(data) {
      // console.log(data);
      if ((data && data.type != "init") || !data) {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchApplications();
      }
    },
    // async onView(document) {
    //   this.viewAppDialog =true
    //   this.SelectedAppData = document
    // },
    
    async newSolution() {
      this.$router.push({ path: "/apps/upload" });
    },
    async fetchApplications() {
      try {
        this.configureLoading = true;
        // let params = {
        //   get_all: true
        // };

        let params = {
        limit: this.pageSize > 0 ? this.pageSize : 10,
        page: this.page,
        isOwner:true,
        installedApps : true
      };
      if (this.search_string) {
        params.search_string = this.search_string;
      }
      // if (this.order_by) {
      //   params.order_by = this.order_by;
      // }
      if (this.order_type) {
        params.order_type = this.order_type;
      }


        // await this.$store.dispatch("entities/fetchEntities", params);
        await this.$store.dispatch("apps/fetchAllInstalledApplications",params );
        if (this.getInstalledApplications) {
          this.allApplications = this.getInstalledApplications.data;
          this.total = this.getInstalledApplications && this.getInstalledApplications.total ? this.getInstalledApplications.total: 0;
          this.currentPage = parseInt(this.getInstalledApplications.page) || 1;
          this.configureLoading = false;
        } else {
          this.configureLoading = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async onUninstall(row) {
      this.$confirm("Are you sure to uninstall the Application? All your data will be deleted permanently and you can't recover them later.", "Warning", {
          confirmButtonText: "Uninstall",
          cancelButtonText: "Cancel",
          type: "warning"
        }).then(() => {
          this.uninstallApplication(row);
        });
    },
    async uninstallApplication(app) {
      this.configureLoading = true
      let params = app
      console.log("app",params,app)
      await this.$store.dispatch("apps/uninstallApplication",params);
      if (this.getAppUninstallStatus) {
        this.configureLoading = false;
        this.fetchApplications();
        this.$notify.success({
          title: "Success",
          message: "Application successfully uninstalled"
        });
        window.location.reload();
      } else {
        this.configureLoading = false;
        this.$notify.error({
          title: "Error",
          message: this.getAppDeletionErrors || "Error uninstalling App"
        });
      }
    },
    async getCompanyInfo() {
    this.loading = true;
    try {
      await this.$store.dispatch(
        "company/fetchCompany",
        this.getActiveWorkspace.company_id
      );
      if (this.getCompanyDetails) {
        this.loading = false;
      }
    } catch (e) {
      this.loading = false;
      this.$notify.error({
        title: "Error",
        message: e,
      });
    }
  },
  changeWorkspace(row){
    this.$confirm("Are you sure switch workspace?",'Warning',{
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning"
    }).then(()=>{
    console.log("change workspace",row);
    })
  }
  },
  watch: {
    "search_string": async function (){
      this.fetchApplications();
    },
    "selectedStatus" : async function() {
          this.fetchApplications();
      },
  }
};
</script>
<style scoped lang="scss">
 .circle {
  position: relative;
  padding-left: 20px;
  text-transform: capitalize;
  font-size: 14px;
  &::after {
    background: #635e5e;
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 50%;
  }
  &.active::after {
    background: #08ad36;
  }
  &.hold::after {
    background: #f19203;
  }
  &.onreview::after {
    background: #2f80ed;
  }
  &.inactive::after {
    background: #635e5e;
  }
  &.declined::after {
    background: #f03232;
  }
}
.card-content {
display: flex;
justify-content: space-between;
}

.left-content {
display: flex;
align-items: center;
}

.right-content {
margin-left: auto;
}

.el-card {
font-size: 12px;
}

.el-card h5 {
font-size: 14px;
}

.card-button {
border-radius: 5px;
}
.imgSize {
height: 4.5em;
width: 4.5em;
margin-right: 5px
}
.doc-title {
  cursor: pointer;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
  color: #6c757d !important;
}

.activestatus {
  font-size: 14px;
  color: #6c757d !important;
}

.circle {
  position: relative;
  padding-left: 20px;
  text-transform: capitalize;
  font-size: 14px;

  &::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 50%;
  }

  &.active::after {
    background: #08ad36;
  }

  &.inactive::after {
    background: #404040;
  }

  &.deleted::after {
    background: #8b1702;
  }

  &.archived::after {
    background: #404040;
  }

  &.disable::after {
    background: #c4c4c4;
  }

  &.viewed::after {
    background: #2f80ed;
  }
}
</style>
<style lang="scss">
.top-buttons-wrapper {
  @media (max-width: 991.98px) {
    display: flex;
    margin-top: 20px;
  }
}

.new-entity-information {
  .el-dialog {
    width: 800px;
    padding-bottom: 15px;

    @media (max-width: 800px) {
      width: 700px;
      margin-top: 40px !important;
    }

    @media (max-width: 700px) {
      width: 600px;
      margin-top: 40px !important;
    }

    @media (max-width: 600px) {
      width: 500px;
      margin-top: 40px !important;
    }

    @media (max-width: 500px) {
      width: 400px;
      margin-top: 40px !important;
    }

    @media (max-width: 400px) {
      width: 300px;
      margin-top: 40px !important;
    }

    .entity-type-radio {
      @media (max-width: 400px) {
        & > label {
          & > span {
            padding-left: 5px !important;
            padding-right: 5px !important;
          }
        }
      }
    }
  }
}
</style>